class AssetsServiceClass {
    
    public socket: any;
    private namespace: string;
    constructor(_socket, _namespace) {
        this.socket = _socket;
        this.namespace = _namespace;
    }

    getAssetsPageService(query: string,projection:any, top: number, skip: number, orderby, reverse: boolean, collectionname: string,
        isMongoQuery: boolean = false) {
        return new Promise<any[]>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject("IO.Socket not connected to /" + this.namespace);
            }
            try {
                this.socket.emit("GET_ASSETS_PAGE", query,projection, top, skip, orderby, reverse, collectionname, isMongoQuery,
                    (err, result) => {
                        if (err) {
                            return reject(err);
                        }
                        resolve(result);
                    }
                );
            } catch (err) {
                reject(err);
            }
        });
    }  
    
    addAssetService(item: any, collectionname: string) {
        return new Promise<any>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('ADD_ASSET', item, collectionname, function (err, result) {
                    if (err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }
    updateAssetService(item: any, collectionname: string) {
        return new Promise<any>((resolve, reject) => {
            if (!this.socket || !this.socket.connected) {
                return reject('IO.Socket not connected to /' + this.namespace);
            };
            try {
                this.socket.emit('UPDATE_ASSET', item, collectionname, function (err, result) {
                    if (err) { return reject(err); }
                    resolve(result);
                });

            } catch (err) {
                reject(err);
            }
        });
    }

 
}